<template>
    <div class="wrapper page"></div>
    <div class="blog">
        <h1>blog is coming soon!</h1>
        <hr width="250px">
        please comeback to take a look at my personal projects
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.blog{
    /* padding-bottom: 1%; */
    margin-bottom: 50px;
}
@media only screen and (max-width: 450px){
    .blog{
        padding: 2% 5% 0 5%;
    }
        }
</style>
